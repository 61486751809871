import React, { useEffect, useState } from "react";
import { http } from "../../../engine/http";
import moment from "moment";
import { withSnackbar } from "react-simple-snackbar";
import { snackOption } from "../../../engine/config";

function PhotoValidation({
  photo,
  event_id,
  onChange,
  openSnackbar,
  isSelected,
  onSelect,
}) {
  let accept;
  let decline;
  const { status } = photo;

  if (status === "null" || status === null) {
    accept = " bg-gray-400  hover:bg-green-600";
    decline = " bg-gray-400  hover:bg-red-600";
  } else if (status === "true") {
    accept = " bg-green-500  hover:bg-green-600";
    decline = " bg-gray-400  hover:bg-red-600";
  } else if (status === "false") {
    accept = " bg-gray-400 hover:bg-green-600 ";
    decline = " bg-red-500  hover:bg-red-600";
  }

  const changeStatus = (value) => {
    onChange(photo.id, value);

    http
      .put("/api/events/" + event_id + "/validation/" + photo.id, { value })
      .then((response) => {
        if (response.status === 200) {
          if (value === "true") {
            http
              .get("/api/watermark/" + event_id + "/" + photo.file_name)
              .then((response) => {
                if (response.status === 200) {
                  openSnackbar("Filigrane crée avec succés");
                }
              });
          }

          if (value === "false") {
            http.delete("/api/watermark/" + event_id + "/" + photo.file_name);
          }
        }
      });
  };

  return (
    <>
      <article className="overflow-hidden rounded-lg shadow-lg">
        <a href="#">
          <img
            alt="Placeholder"
            className="block inset-0  w-full object-cover"
            style={{ minHeight: "350px" }}
            src={"/" + photo.file_source}
          />
        </a>

        <header className="flex items-left flex-col justify-between leading-tight p-2 md:p-4">
          <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
            {photo.id} - {photo.name} {photo.surname}
          </h6>
          <p className="text-grey-darker text-sm font-normal	">
            {moment(photo.creation_date).format("LLLL")}
          </p>
          <div style={{ marginTop: -25 }}>
            <label className={"items-center justify-end flex "}>
              <div className="text-right bg-white border-2 rounded border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500">
                <input
                  onClick={(e) => {
                    onSelect(photo.id, e.target.checked);
                  }}
                  // checked={isSelected}
                  type="checkbox"
                  className="opacity-0 absolute"
                />
                <svg
                  className="fill-current hidden w-4 h-4 text-blue-500 pointer-events-none"
                  viewBox="0 0 20 20"
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                </svg>
              </div>
              <div className="select-none font-bold text-gray-700">
                <i className="fas fa-trash-alt mr-1 text-sm" />
              </div>
            </label>
          </div>
        </header>

        <footer className="flex items-center justify-between leading-none p-2 md:p-4">
          <a
            className={
              "mr-3 w-full cursor-pointer lg:w-6/12 rounded  text-white text-base font-bold  uppercase px-3 py-4  outline-none focus:outline-none  ease-linear transition-all duration-150 " +
              accept
            }
            onClick={() => changeStatus("true")}
            type="button"
          >
            <i className="fas fa-check mr-4 text-sm" />
            Accepter
          </a>
          <a
            className={
              "w-full cursor-pointer lg:w-6/12 rounded  text-white  text-base font-bold  uppercase px-3 py-4  outline-none focus:outline-none  ease-linear transition-all duration-150" +
              decline
            }
            onClick={() => changeStatus("false")}
            type="button"
          >
            <i className="fas fa-minus-circle mr-1 text-sm" />
            Refuser
          </a>
        </footer>
      </article>
    </>
  );
}

export default withSnackbar(PhotoValidation, snackOption);
