import React, { useContext } from "react";
import { AppContext } from "./App.context";
import { Redirect, Route, useLocation } from "react-router-dom";

export default function ProtectedRoute({ component: Component, ...rest }) {
  const app = useContext(AppContext);
  const location = useLocation();

  return (
    <Route {...rest}>
      {app.isConnected === true ? (
        <Component />
      ) : (
        <Redirect to={{ pathname: "/auth", state: { from: location } }} />
      )}
    </Route>
  );
}
