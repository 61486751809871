import React, { useEffect, useState } from "react";
import { withApp } from "../../../engine/App.context";
import { withSnackbar } from "react-simple-snackbar";
import { snackOption } from "../../../engine/config";
import ValidationForms from "../../components/Cards/Validation.forms";

function Users(props) {
  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <ValidationForms />
        </div>
      </div>
    </>
  );
}

export default withApp(withSnackbar(Users, snackOption));
