import React, { useEffect, useState } from "react";
import { withApp } from "../../../engine/App.context";
import { withSnackbar } from "react-simple-snackbar";
import { snackOption } from "../../../engine/config";
import { http } from "../../../engine/http";
import ListUsersTable from "../../components/Cards/ListUsers.table";

function Users(props) {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    http.get("/api/users").then((response) => {
      setUsers(response.data.users);
    });
    // Specify how to clean up after this effect:
    return () => {};
  }, []); // empty 2nd arg - only runs once

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <ListUsersTable users={users} />
        </div>
      </div>
    </>
  );
}

export default withApp(withSnackbar(Users, snackOption));
