import React from "react";
import { withApp } from "../../../engine/App.context";

function Navbar({ app }) {
  return (
    <>
      {/* Navbar */}
      <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-no-wrap md:justify-start flex items-center p-4">
        <div className="w-full mx-autp items-center flex justify-between md:flex-no-wrap flex-wrap md:px-10 px-4">
          {/* Brand */}
          <a
            className="text-white text-sm uppercase hidden lg:inline-block font-semibold"
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
            Tableau de bord
          </a>
          <div className="relative flex flex-col text-right min-w-0 break-words bg-gray-100	 rounded mb-6 xl:mb-0 shadow-lg uppercase">
            <div className="flex-auto p-4">
              <div className="flex flex-wrap">
                <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                  <h5 className="text-gray-500 uppercase font-bold text-xs">
                    Bienvenue
                  </h5>
                  <span className="font-semibold text-xl text-gray-800 uppercase">
                    {app.user.name} {app.user.surname}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* User */}
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}

export default withApp(Navbar);
