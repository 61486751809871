import React from "react";
import { withApp } from "../../engine/App.context";
import { withSnackbar } from "react-simple-snackbar";
import { snackOption } from "../../engine/config";
import { http } from "../../engine/http";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import "react-bnb-gallery/dist/style.css";
import ReactBnbGallery from "react-bnb-gallery";
import moment from "moment";

class Event extends React.Component {
  state = {
    viewerIsOpen: false,
    photos: [],
    currentImage: 0,
    ready: false,
    currentId: 0,
    event: null,
    innerWidth: 0,
    loading: true,
    chargement: 0,
  };

  setChargement = () => {
    const { chargement, photos } = this.state;

    if (chargement + 1 === photos.length) {
      this.setState({ chargement: chargement + 1, loading: false });
    } else {
      this.setState({ chargement: chargement + 1 });
    }
  };

  componentDidMount = async () => {
    const slug = this.props.match.params.id;

    await http.get("/api/events/" + slug).then(async (events) => {
      this.setState({ event: events.data.events });
    });

    await http
      .get("/api/events/" + slug + "/published")
      .then(async (events) => {
        const { event } = events.data;
        const w = window.innerWidth;

        event.map((photo) => {
          photo.photo = "/public/" + photo.events_id + "/wm/" + photo.src;
          photo.caption =
            photo.id + " : Par " + photo.name + " " + photo.surname;
          photo.subcaption = moment(photo.creation_date).format(
            "D MMMM YYYY à HH:mm:ss"
          );
        });

        this.setState({ photos: event, ready: true, innerWidth: w });
      });
  };

  closeLightbox = () => {
    this.setState({ currentImage: 0, currentId: 0, viewerIsOpen: false });
  };

  updateUrl = () => {
    let newParams = "";
    const { currentImage } = this.state;
    newParams = "?photo" + currentImage;
    window.history.replaceState(null, null, newParams);
  };

  openLightbox = (event, { photo, index }) => {
    this.setState({
      currentId: photo.id,
      currentImage: index,
      viewerIsOpen: true,
    });
  };

  render() {
    const {
      photos,
      loading,
      viewerIsOpen,
      ready,
      currentImage,
      event,
      chargement,
    } = this.state;

    if (event === null) {
      return <div></div>;
    }

    return (
      <div className={"public flex flex-col items-start"}>
        <div
          style={{ backgroundColor: "#3ACC7A", borderRadius: "3px" }}
          className={" w-full p-10 flex  justify-between flex-wrap"}
        >
          <h3 className={"font-bold text-xl text-white"}>{event.name}</h3>
          <span className={"font-semibold text-lg text-grey-700"}>
            Du {moment(event.start_date).format("D MMMM YYYY")} au{" "}
            {moment(event.end_date).format("D MMMM YYYY")}
          </span>
        </div>
        <a
          href={"/events"}
          className={
            "text-sm rounded py-2 px-4 bg-blue-500 font-normal block  bg-transparent text-white align-left text-center"
          }
          style={{ backgroundColor: "#3ACC7A", margin: "10px 0 10px 0 " }}
        >
          <i className="fas fa-arrow-left mr-2 text-xs" />
          Revenir à la liste
        </a>
        {ready ? (
          <div className={"w-full"} style={{ margin: "0 auto" }}>
            <span
              className={"font-semibold text-lg text-grey-700"}
              style={{ marginBottom: "10px", display: "inline-block" }}
            >
              Il y a {photos.length} photos
            </span>

            <div
              style={{
                display: loading ? "block" : "none",
                background: "#e4e7eb",
                position: "absolute",
                margin: "0 auto",
                width: "100%",
                height: "100%",
                zIndex: "1000000",
                paddingTop: "50px",
                marginTop: "-50px",
              }}
            >
              <img
                src={require("../../assets/img/loader.svg")}
                style={{ margin: "0 auto" }}
              />
              <p
                style={{ textAlign: "center" }}
                className={"text-grey-darker text-sm font-normal"}
              >
                Chargement des photos {chargement} sur {photos.length}
              </p>
            </div>
            <br />

            {photos.length > 0 && (
              <>
                <ResponsiveMasonry
                  columnsCountBreakPoints={{
                    350: 1,
                    750: 2,
                    900: 3,
                    1200: 4,
                  }}
                >
                  <Masonry gutter={10}>
                    {photos.map((photo, index) => (
                      <>
                        <img
                          loading="lazy"
                          style={{ width: "100%" }}
                          className={"rounded cursor-pointer "}
                          src={
                            "/public/" + photo.events_id + "/wm/" + photo.src
                          }
                          onClick={(e) =>
                            this.openLightbox(e, { photo, index })
                          }
                          onLoad={this.setChargement}
                        />
                        <span className={"sub font-normal text-grey-700"}>
                          {photo.caption}
                        </span>
                      </>
                    ))}
                  </Masonry>
                </ResponsiveMasonry>
                <ReactBnbGallery
                  activePhotoIndex={currentImage}
                  show={viewerIsOpen}
                  photos={photos}
                  onClose={this.closeLightbox}
                />
              </>
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

export default withApp(withSnackbar(Event, snackOption));
