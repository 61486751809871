import React, { useEffect, useState } from "react";

// components

import CardStats from "../../components/Cards/CardStats.js";
import { http } from "../../../engine/http";

export default function HeaderStats() {
  const [stats, setStats] = useState({
    contributors: 0,
    recent: 0,
    validate: 0,
    total: 0,
  });

  useEffect(() => {
    http.get("/api/stats/").then((response) => {
      setStats(response.data);
    });

    // Specify how to clean up after this effect:
    return () => {};
  }, []); // empty 2nd arg - only runs once

  const { contributors, recent, validate, total } = stats;

  return (
    <>
      {/* Header */}
      <div className="relative bg-blue-600 md:pt-32 pb-32 pt-12">
        <div className="px-4 md:px-10 mx-auto w-full">
          <div>
            {/* Card stats */}
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Photos à valider"
                  statTitle={validate}
                  statIconName="fas fa-check"
                  statIconColor="bg-green-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Photos récentes (24h)"
                  statTitle={recent}
                  statIconName="fas fa-clock"
                  statIconColor="bg-yellow-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Contributeurs"
                  statTitle={contributors}
                  statIconName="fas fa-user-plus"
                  statIconColor="bg-pink-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Photos publiées"
                  statTitle={total}
                  statIconName="fas fa-upload"
                  statIconColor="bg-blue-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
