import React from "react";
import { withApp } from "../../../engine/App.context";
import { withSnackbar } from "react-simple-snackbar";
import { withRouter } from "react-router";
import { snackOption } from "../../../engine/config";
import { http } from "../../../engine/http";
import DropDown from "react-dropdown";
import PhotoValidation from "./Photo.validation";
import Dropdown from "../../../components/DropDown";
import { withAlert } from "react-alert";

// components

class ValidationForms extends React.Component {
  state = {
    event: {},
    photos: [],
    initialization: false,
    validate_checked: true,
    refused_checked: false,
    accepted_checked: false,
    totNull: 0,
    columns: "w-full lg:w-6/12 xl:w-3/12",
    totTrue: 0,
    totFalse: 0,
    email: "",
    emails: [],
    selected: [],
  };

  onSelect = (id, value) => {
    const { selected } = this.state;

    if (value === true) {
      this.setState({
        selected: [...selected, id],
      });
    } else if (value === false) {
      let index = selected.indexOf(id);
      selected.splice(index, 1);
      this.setState({ selected });
    }
  };

  deleteByLot = () => {
    const { selected, photos } = this.state;


    const files_name = [];

    selected.map((id) => {
      let pos = photos
        .map((e) => {
          return e.id;
        })
        .indexOf(id);

      files_name.push(photos[pos].file_name);
    });


    let message = "";

    if (selected.length === 1) {
      message = "Voulez-vous supprimer définitement cette photo ? ";
    } else {
      message =
        "Voulez-vous supprimer définitement ces " +
        selected.length +
        " photos ?";
    }

    if (window.confirm(message)) {
      http
        .put("/api/events/" + this.props.match.params.id + "/deletelots", {
          ids: selected,
          files_name,
        })
        .then((response) => {
          const tot = response.data.updatePhotos.affectedRows;

          this.props.alert.show(
            <p>
              {tot} photo{tot > 1 && "s"} supprimée{tot > 1 && "s"}
            </p>,
            {
              timeout: 5000, // custom timeout just for this one alert
              type: "success",
            }
          );
          window.location.reload();
        });
    }
  };

  updatePhotos = (id, value) => {
    const { photos } = this.state;

    const elementsIndex = photos.findIndex((element) => element.id === id);

    let newArray = [...photos];

    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      status: value,
    };
    this.setState(
      {
        photos: newArray,
      },
      () => this.countTotal()
    );
  };

  countTotal = () => {
    const { photos } = this.state;

    let totNull = 0;
    let totTrue = 0;
    let totFalse = 0;
    photos.map((photo) => {
      photo.status === null && totNull++;
      photo.status === "true" && totTrue++;
      photo.status === "false" && totFalse++;
    });

    this.setState({
      initialization: true,
      totNull,
      totTrue,
      totFalse,
    });
  };

  // Hydrate the component if update or create
  componentDidMount() {
    const { initialization } = this.state;
    // ID params get the event data

    if (initialization === false && this.props.match.params.id !== undefined) {
      http.get("/api/events/" + this.props.match.params.id).then((response) => {
        const { events } = response.data;

        // If data empty that mean event does not exist
        if (events === undefined) {
          this.props.history.push("/admin/events");
        } else {
          // Get photos to validate
          http
            .get("/api/events/" + this.props.match.params.id + "/validation")
            .then((response) => {
              const { photos } = response.data;
              let r = photos.filter(
                (v, i, a) => a.findIndex((t) => t.email === v.email) === i
              );

              let emails = r.map((i) => {
                return {
                  value: String(i.email),
                  label: i.name + " " + i.surname + " | " + i.email,
                };
              });

              this.setState(
                {
                  initialization: true,
                  photos,
                  event: events,
                  emails,
                },
                () => this.countTotal()
              );
            });
        }
      });
    }
  }

  orderAndFilter = () => {
    const {
      photos,
      refused_checked,
      validate_checked,
      accepted_checked,
      email,
    } = this.state;

    const filtered = photos.filter((photo) => {
      const filter_conditions = [];

      if (validate_checked === true) {
        filter_conditions.push(
          photo.status === null || photo.status === "null"
        );
      }

      if (accepted_checked === true) {
        filter_conditions.push(photo.status === "true");
      }

      if (refused_checked === true) {
        filter_conditions.push(photo.status === "false");
      }

      const cond = filter_conditions.some((e) => e === true);

      // filter by email
      if (cond === true && email !== "") {
        return photo.email === email;
      }

      return cond;
    });

    return filtered;
  };

  render() {
    const {
      columns,
      photos,
      event,
      refused_checked,
      validate_checked,
      accepted_checked,
      totFalse,
      totNull,
      email,
      totTrue,
      emails,
      selected,
    } = this.state;

    const { id } = this.props.match.params;

    const cleanPhotos = this.orderAndFilter();


    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-gray-500 text-sm font-bold uppercase">
                Valider les photos
              </h6>
              <div>
                <h6 className="text-gray-500 text-sm font-bold uppercase">
                  Plus que {totNull} Photos à valider
                </h6>
              </div>
            </div>
            <div className="text-center flex justify-between">
              <h6 className="text-gray-800 text-xl font-bold">{event.name}</h6>
            </div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
              <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
                Filtres et triages
              </h6>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Status de la photo
                    </label>
                    <div className={"flex justify-between"}>
                      <label
                        className={
                          "flex justify-start items-start  py-2 px-6 rounded " +
                          (validate_checked === true
                            ? " bg-gray-300 "
                            : " bg-gray-100 ")
                        }
                      >
                        <div className="bg-white border-2 rounded border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500">
                          <input
                            onClick={() =>
                              this.setState({
                                validate_checked: !validate_checked,
                              })
                            }
                            checked={validate_checked}
                            type="checkbox"
                            className="opacity-0 absolute"
                          />
                          <svg
                            className="fill-current hidden w-4 h-4 text-blue-500 pointer-events-none"
                            viewBox="0 0 20 20"
                          >
                            <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                          </svg>
                        </div>
                        <div className="select-none font-bold text-gray-700">
                          A valider ({totNull})
                        </div>
                      </label>
                      <label
                        className={
                          "flex justify-start items-start  py-2 px-6 rounded " +
                          (accepted_checked === true
                            ? " bg-gray-300 "
                            : " bg-gray-100 ")
                        }
                      >
                        <div className="bg-white border-2 rounded border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500">
                          <input
                            onClick={() =>
                              this.setState({
                                accepted_checked: !accepted_checked,
                              })
                            }
                            checked={accepted_checked}
                            type="checkbox"
                            className="opacity-0 absolute"
                          />
                          <svg
                            className="fill-current hidden w-4 h-4 text-green-400 pointer-events-none"
                            viewBox="0 0 20 20"
                          >
                            <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                          </svg>
                        </div>
                        <div className="select-none font-bold text-gray-700">
                          Acceptée ({totTrue})
                        </div>
                      </label>
                      <label
                        className={
                          "flex justify-start items-start  py-2 px-6 rounded " +
                          (refused_checked === true
                            ? " bg-gray-300 "
                            : " bg-gray-100 ")
                        }
                      >
                        <div className="bg-white border-2 rounded border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500">
                          <input
                            onClick={() =>
                              this.setState({
                                refused_checked: !refused_checked,
                              })
                            }
                            checked={refused_checked}
                            type="checkbox"
                            className="opacity-0 absolute"
                          />
                          <svg
                            className="fill-current hidden w-4 h-4 text-red-500 pointer-events-none"
                            viewBox="0 0 20 20"
                          >
                            <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                          </svg>
                        </div>
                        <div className="select-none font-bold text-gray-700">
                          Refusée ({totFalse})
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Filtrer par utilisateur
                    </label>
                    <Dropdown
                      open
                      value={email}
                      onChange={(e) => this.setState({ email: e.value })}
                      className={
                        "px-2 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      }
                      options={emails}
                      placeholder="Filter par un email..."
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Changer l'affichage
                    </label>
                    <DropDown
                      className={
                        "px-2 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      }
                      options={[
                        {
                          value: String("w-full lg:w-6/12 xl:w-3/12"),
                          label: "4 colonnes",
                        },
                        {
                          value: String("w-full lg:w-6/12 xl:w-4/12"),
                          label: "3 colonnes",
                        },
                        {
                          value: String(" w-6/12"),
                          label: "2 colonnes",
                        },
                        {
                          value: String(" w-full lg:w-12/12 xl:w-12/12"),
                          label: "1 colonne",
                        },
                      ]}
                      value={columns}
                      onChange={(e) => this.setState({ columns: e.value })}
                      placeholder="Choisir..."
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Suppression par lot
                    </label>
                    {selected.length > 0 && (
                      <a
                        className={
                          "w-full cursor-pointer lg:w-6/12 rounded  text-white  text-base font-bold  uppercase px-3 py-4  outline-none focus:outline-none  ease-linear transition-all duration-150 bg-red-500  hover:bg-red-600"
                        }
                        onClick={this.deleteByLot}
                        type="button"
                      >
                        <i className="fas fa-trash-alt mr-1 text-sm" />
                        Supprimer {selected.length} photo
                        {selected.length > 1 && "s"}
                      </a>
                    )}
                  </div>
                </div>

                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Par date
                    </label>
                  </div>
                </div>
              </div>

              <hr className="mt-6 border-b-1 border-gray-400" />
            </form>
          </div>
        </div>

        <div className="flex flex-wrap">
          <div className="text-center flex justify-between w-full">
            <h6 className="text-gray-800 text-xl font-bold px-6 pb-6">
              {cleanPhotos.length} photos disponible
            </h6>
          </div>
          {cleanPhotos.map((photo) => (
            <div className={" px-4 mt-10" + columns}>
              <PhotoValidation
                isSelected={selected.indexOf(photo.id) !== -1}
                onSelect={this.onSelect}
                photo={photo}
                event_id={id}
                onChange={this.updatePhotos}
              />
            </div>
          ))}
        </div>
      </>
    );
  }
}

export default withApp(
  withSnackbar(withRouter(withAlert()(ValidationForms, snackOption)))
);
