import React from "react";

// components

import UpsertEvent from "../../components/Cards/UpsertEvent.forms.js";

export default class UpsertEventView extends React.Component {
  render() {
    return (
      <>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-12/12 px-4">
            <UpsertEvent />
          </div>
        </div>
      </>
    );
  }
}
