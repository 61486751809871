import React, { useEffect, useState } from "react";
import "./assets/css/App.css";
import { withApp } from "./engine/App.context";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Upload from "./screens/upload/Upload.screen";
import Forbidden from "./screens/fobidden/Forbidden.screen";
import Event from "./screens/event/Event.screen";
import Events from "./screens/events/Events.screen";
import ProtectedRoute from "./engine/Protected.route";
import Admin from "./admin/layouts/Admin";
import Auth from "./admin/layouts/Auth";
import "@fortawesome/fontawesome-free/css/all.min.css";
import moment from "moment";
import { locale_fr } from "./engine/config";

moment.locale("fr", locale_fr);

function App({ app }) {
  if (app.appReady === false) {
    return <div></div>;
  }

  // https://tailwindcomponents.com/component/tailwind-css-admin-template
  return (
    <>
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
        rel="stylesheet"
      />
      <Router>
        <Switch>
          <Route exact path="/" component={Upload} />
          <Route path="/events/:id" component={Event} />
          <Route path="/events/" component={Events} />
          <Route path="/auth" component={Auth} />
          <ProtectedRoute path="/admin" component={Admin} />
          <Route path="/" component={Forbidden} />
        </Switch>
      </Router>
    </>
  );
}

export default withApp(App);
