import * as React from "react";
import { http } from "./http";
import jwt_decode from "jwt-decode";

const defaultContext = {};

export const AppContext = React.createContext(defaultContext);

export class AppProvider extends React.Component {
  async componentDidMount() {
    let token = await localStorage.getItem("token");
    let categories = [];
    await http.get("/api/categories").then((response) => {
      categories = response.data.categories;
    });

    if (token !== null) {
      // TODO check token validity
      this.connectApp(token, { categories });
    } else {
      this.setState({ appReady: true, categories });
    }
  }

  disconnect = async (e) => {
    e.preventDefault();
    await localStorage.removeItem("token");
    this.setState({ isConnected: false, token: null });
  };

  connectApp = (token, rest) => {
    localStorage.setItem("token", token);
    http.defaults.headers.common["x-access-token"] = token;

    let user = null;
    try {
      user = jwt_decode(token);
    } catch (e) {
      localStorage.removeItem("token");
      window.alert(
        "Il y a eu une erreur avec votre session. Merci de vous reconnecter"
      );
      window.location.reload();
    }

    this.setState({
      token,
      isConnected: true,
      appReady: true,
      ...rest,
      user,
    });
  };

  setXAccessTokenInHttp = (token) => {};

  auth = (email, pwd, cb) => {
    http
      .post("/api/auth", {
        email,
        pwd,
      })
      .then((response) => {
        if (
          response.data.error === undefined &&
          response.data.token !== undefined
        ) {
          this.connectApp(response.data.token);
          cb(response.data);
        } else {
          cb(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  state = {
    token: null,
    isConnected: false,
    appReady: false,
    user: {},
    categories: [],
    auth: this.auth,
    disconnect: this.disconnect,
  };

  render() {
    const { children } = this.props;
    return (
      <AppContext.Provider value={this.state}>{children}</AppContext.Provider>
    );
  }
}

export const withApp = (Component) => (props) => (
  <AppContext.Consumer>
    {(store) => <Component app={store} {...props} />}
  </AppContext.Consumer>
);
