import React, { Component, createRef } from "react";
import classNames from "classnames";

const DEFAULT_PLACEHOLDER_STRING = "Select...";

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      selected: this.parseValue(props.value, props.options) || {
        label:
          typeof props.placeholder === "undefined"
            ? DEFAULT_PLACEHOLDER_STRING
            : props.placeholder,
        value: "",
      },
      isOpen: false,
    };
    this.dropdownRef = createRef();
    this.mounted = true;
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.fireChangeEvent = this.fireChangeEvent.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      if (this.props.value) {
        let selected = this.parseValue(this.props.value, this.props.options);
        if (selected !== this.state.selected) {
          this.setState({ selected });
        }
      } else {
        this.setState({
          selected: {
            label:
              typeof this.props.placeholder === "undefined"
                ? DEFAULT_PLACEHOLDER_STRING
                : this.props.placeholder,
            value: "",
          },
        });
      }
    }
  }

  componentDidMount() {
    document.addEventListener("click", this.handleDocumentClick, false);
    document.addEventListener("touchend", this.handleDocumentClick, false);
  }

  componentWillUnmount() {
    this.mounted = false;
    document.removeEventListener("click", this.handleDocumentClick, false);
    document.removeEventListener("touchend", this.handleDocumentClick, false);
  }

  handleMouseDown(event) {
    if (this.props.onFocus && typeof this.props.onFocus === "function") {
      this.props.onFocus(this.state.isOpen);
    }
    if (event.type === "mousedown" && event.button !== 0) return;
    // event.stopPropagation();
    // event.preventDefault();

    if (!this.props.disabled) {
      this.setState({
        isOpen: !this.state.isOpen,
      });
    }
  }

  parseValue(value, options) {
    let option;

    if (typeof value === "string") {
      for (var i = 0, num = options.length; i < num; i++) {
        if (options[i].type === "group") {
          const match = options[i].items.filter((item) => item.value === value);
          if (match.length) {
            option = match[0];
          }
        } else if (
          typeof options[i].value !== "undefined" &&
          options[i].value === value
        ) {
          option = options[i];
        }
      }
    }

    return option || value;
  }

  setValue = (value, label) => {
    let newState = {
      search: value,
      selected: {
        value,
        label,
      },
      isOpen: false,
    };
    this.fireChangeEvent(newState);
    this.setState(newState);
  };

  fireChangeEvent(newState) {
    if (newState.selected !== this.state.selected && this.props.onChange) {
      this.props.onChange(newState.selected);
    }
  }

  renderOption(option) {
    let value = option.value;
    if (typeof value === "undefined") {
      value = option.label || option;
    }
    let label = option.label || option.value || option;
    let isSelected =
      value === this.state.selected.value || value === this.state.selected;

    const classes = {
      [`${this.props.baseClassName}-option`]: true,
      [option.className]: !!option.className,
      "is-selected": isSelected,
    };

    const optionClass = classNames(classes);

    return (
      <div
        key={value}
        className={optionClass}
        onMouseDown={this.setValue.bind(this, value, label)}
        onClick={this.setValue.bind(this, value, label)}
        role="option"
        aria-selected={isSelected ? "true" : "false"}
      >
        {label}
      </div>
    );
  }

  buildMenu() {
    let { options, baseClassName } = this.props;
    const { search } = this.state;

    let filteredOptions = options.filter((o) => o.value.includes(search));

    let ops = filteredOptions.map((option) => {
      if (option.type === "group") {
        let groupTitle = (
          <div className={`${baseClassName}-title`}>{option.name}</div>
        );
        let _options = option.items.map((item) => this.renderOption(item));

        return (
          <div
            className={`${baseClassName}-group`}
            key={option.name}
            role="listbox"
            tabIndex="-1"
          >
            {groupTitle}
            {_options}
          </div>
        );
      } else {
        return this.renderOption(option);
      }
    });

    return ops.length ? (
      ops
    ) : (
      <div className={` text-gray-700 p-2 font-normal`}>
        Aucun résultat trouvé
      </div>
    );
  }

  handleDocumentClick(event) {
    if (this.mounted) {
      if (!this.dropdownRef.current.contains(event.target)) {
        if (this.state.isOpen) {
          this.setState({ isOpen: false });
        }
      }
    }
  }

  isValueSelected() {
    return (
      typeof this.state.selected === "string" ||
      this.state.selected.value !== ""
    );
  }

  render() {
    const { search } = this.state;

    const {
      baseClassName,
      controlClassName,
      placeholderClassName,
      menuClassName,
      arrowClassName,
      arrowClosed,
      arrowOpen,
      className,
    } = this.props;

    const disabledClass = this.props.disabled ? "Dropdown-disabled" : "";
    const placeHolderValue =
      typeof this.state.selected === "string"
        ? this.state.selected
        : this.state.selected.label;

    const dropdownClass = classNames({
      [`${baseClassName}-root`]: true,
      [className]: !!className,
      "is-open": this.state.isOpen,
    });
    const controlClass = classNames({
      [`${baseClassName}-control`]: true,
      [controlClassName]: !!controlClassName,
      [disabledClass]: !!disabledClass,
    });
    const placeholderClass = classNames({
      [`${baseClassName}-placeholder`]: true,
      [placeholderClassName]: !!placeholderClassName,
      "is-selected": this.isValueSelected(),
    });
    const menuClass = classNames({
      [`${baseClassName}-menu`]: true,
      [menuClassName]: !!menuClassName,
    });
    const arrowClass = classNames({
      [`${baseClassName}-arrow`]: true,
      [arrowClassName]: !!arrowClassName,
    });

    const value = <div className={placeholderClass}>{placeHolderValue}</div>;
    const menu = this.state.isOpen ? (
      <div className={menuClass} aria-expanded="true">
        {this.buildMenu()}
      </div>
    ) : null;

    return (
      <div ref={this.dropdownRef} className={dropdownClass}>
        <div
          className={controlClass}
          aria-haspopup="listbox"
          style={{ padding: 0 }}
        >
          <div className={"flex justify-center flex-center align-center"}>
            <i
              className="fas fa-trash mr-2 text-xxl text-red-400 cursor-pointer"
              style={{ marginTop: "10px", marginLeft: "10px" }}
              onClick={() => {
                this.setValue("", "");
              }}
            />
            <input
              placeholder={"Filter par un email..."}
              style={{ padding: "8px 52px 8px 10px" }}
              onMouseDown={this.handleMouseDown.bind(this)}
              onTouchEnd={this.handleMouseDown.bind(this)}
              type="text"
              className=" placeholder-gray-400 text-gray-700 z-50  rounded border-gray-400 w-full "
              value={search}
              onChange={(e) => this.setState({ search: e.target.value })}
            />
            <div
              className={`${baseClassName}-arrow-wrapper`}
              style={{ width: "50px" }}
            >
              {arrowOpen && arrowClosed ? (
                this.state.isOpen ? (
                  arrowOpen
                ) : (
                  arrowClosed
                )
              ) : (
                <span className={arrowClass} />
              )}
            </div>
          </div>
        </div>
        {menu}
      </div>
    );
  }
}

Dropdown.defaultProps = { baseClassName: "Dropdown" };
export default Dropdown;
