import React from "react";
import { withApp } from "../../engine/App.context";
import { withSnackbar } from "react-simple-snackbar";
import { snackOption } from "../../engine/config";
import DropZone, { maxFiles } from "../../components/DropZone";
import { withAlert } from "react-alert";

class Upload extends React.Component {
  state = {
    fileRejections: [],
  };

  updateState = (key, value) => {
    if (this.state[key] !== value) {
      this.setState({ [key]: value });
    }
  };

  updateFileRejection = (value) => {
    if (this.state.fileRejections !== value) {
      this.setState({ fileRejections: value });
      if (Object.keys(value).length > 0) {
        this.props.alert.show(
          <p>
            Les photos doivent être au format PNG ou JPG <br />
            Maximum {maxFiles} photos par envoi
          </p>,
          {
            timeout: 5000, // custom timeout just for this one alert
            type: "error",
          }
        );
      }
    }
  };

  render() {
    const {} = this.props.app;

    return (
      <div className={"full-page"}>
        <DropZone
          updateState={this.updateState}
          updateFileRejection={this.updateFileRejection}
        />
      </div>
    );
  }
}

export default withApp(withAlert()(Upload));
