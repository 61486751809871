import React from "react";
import { Link } from "react-router-dom";
import { withApp } from "../../../engine/App.context";
import { withSnackbar } from "react-simple-snackbar";
import { snackOption } from "../../../engine/config";

class Login extends React.Component {
  state = {
    email: "",
    pwd: "",
    error: null,
  };

  connect = (e) => {
    e.preventDefault();
    const { email, pwd } = this.state;
    const { auth } = this.props.app;

    auth(email, pwd, (e) => {
      if (e.error !== undefined) {
        this.setState({ error: e.error });
      } else {
        this.props.history.push("/admin");
      }
    });
  };

  render() {
    const { error } = this.state;
    return (
      <>
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    <h6 className="text-gray-600 text-sm font-bold">
                      Bienvenu dans l'accès admin de l'ACCV
                    </h6>
                  </div>
                  <hr className="mt-6 border-b-1 border-gray-400" />
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <div className="text-gray-500 text-center mb-3 font-bold">
                    <small>Merci de renseigner vos identifiants</small>
                  </div>
                  <form>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <input
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                        id="email"
                        type="email"
                        name="email"
                        autoComplete="email"
                        required
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="@..."
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="password"
                      >
                        Mot de passe
                      </label>
                      <input
                        onChange={(e) => this.setState({ pwd: e.target.value })}
                        id="password"
                        type="password"
                        name="password"
                        required
                        placeholder="secret..."
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      />
                    </div>

                    <div className="text-center mt-6">
                      <button
                        onClick={this.connect}
                        className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        type="submit"
                      >
                        Se connecter
                      </button>
                    </div>

                    {error !== null && (
                      <div className="text-red-500 text-center mt-6  font-bold">
                        <small>{error}</small>
                      </div>
                    )}
                  </form>
                </div>
              </div>
              <div className="flex flex-wrap mt-6 relative">
                <div className="w-1/2">
                  <a
                    href="#"
                    onClick={(e) => e.preventDefault()}
                    className="text-gray-300"
                  >
                    <small>Mot de passe oublié ?</small>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withApp(withSnackbar(Login, snackOption));
