// Set config defaults when creating the instance
import moment from "moment";

import axios from "axios";
export const http = axios.create({});
// Add a response interceptor
http.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response.status === 403) {
      localStorage.removeItem("token");
      window.alert("Votre session a expiré, merci de vous reconnecter");
      window.location.reload();
    }

    // Do something with response error
    return Promise.reject(error);
  }
);

http.defaults.headers.common["x-access-token"] = "";
