import React from "react";
import { withApp } from "../../engine/App.context";
import { withSnackbar } from "react-simple-snackbar";
import { snackOption } from "../../engine/config";

class Forbidden extends React.Component {
  render() {
    const {} = this.props.app;

    return (
      <div>
        <h2
          className={"p-10 m-96 text-black text-5xl"}
          style={{ margin: "30px" }}
        >
          Erreur 404 - Page non trouvable
        </h2>
      </div>
    );
  }
}

export default withApp(withSnackbar(Forbidden, snackOption));
