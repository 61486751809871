import React, { useEffect, useState } from "react";
import { withApp } from "../../engine/App.context";
import { withSnackbar } from "react-simple-snackbar";
import { snackOption } from "../../engine/config";
import { http } from "../../engine/http";
import DropDown from "react-dropdown";
import moment from "moment";

function Events(props) {
  const [events, setEvents] = useState([]);
  const [search, setSearch] = useState("");
  const [categorie, setCategorie] = useState(null);

  let filteredEvents = events.filter((event) => event.name.includes(search));
  if (categorie !== null) {
    filteredEvents = filteredEvents.filter(
      (event) => event.categories_id === Number(categorie)
    );
  }

  useEffect(() => {
    // watermark from remote source

    http
      .get("/api/events_public/")
      .then((events) => setEvents(events.data.events));
    return () => {};
  }, []);

  return (
    <div className={"public"}>
      <form className="md:flex  flex-row flex-wrap items-center lg:ml-auto ">
        <div className="relative flex w-full flex-wrap items-stretch">
          <br />
          <span className="z-10 h-full leading-snug font-normal absolute text-center text-gray-400 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
            <i className="fas fa-search" />
          </span>
          <input
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            placeholder="Rechercher..."
            className="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full pl-10"
          />
          <DropDown
            className={
              "mt-2 px-2 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
            }
            options={[
              { value: null, label: "Tous" },

              ...props.app.categories.map((cat) => {
                return { value: String(cat.id), label: cat.name };
              }),
            ]}
            value={categorie}
            onChange={(e) => setCategorie(e.value)}
            placeholder="Trier par catégorie..."
          />
        </div>
      </form>
      <br />
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white "
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className={"font-semibold text-lg text-gray-800"}>
                Liste des événements
              </h3>
            </div>
            <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right"></div>
          </div>
        </div>

        <div className="block w-full ">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse table-fixed">
            <thead>
              <tr>
                <th
                  className={
                    "w-1/2 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-600 border-gray-200 "
                  }
                >
                  Nom
                </th>
                <th
                  className={
                    "hidden md:block px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap  bg-gray-100 text-gray-600 border-gray-200 font-semibold text-left "
                  }
                >
                  Description
                </th>
                <th
                  className={
                    "w-1/4 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold  bg-gray-100 text-gray-600 border-gray-200 text-left "
                  }
                >
                  Catégorie
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold bg-gray-100 text-gray-600 border-gray-200 text-left "
                  }
                >
                  Lien
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredEvents.map((event) => (
                <>
                  <tr className={"border"}>
                    <td className="border-t-0 font-bold text-gray-700 px-6 align-middle border-l-0 border-r-0 text-m whitespace-no-wrap p-4">
                      {event.name}
                    </td>
                    <td className="hidden md:block border-t-0 font-bold text-gray-700 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                      {event.description}
                    </td>
                    {/*<td className="border-t-0 font-bold px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">*/}
                    {/*  {moment(event.start_date).format("D MMMM YYYY")}*/}
                    {/*</td>*/}
                    {/*<td className="border-t-0 font-bold px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">*/}
                    {/*  {moment(event.end_date).format("D MMMM YYYY")}*/}
                    {/*</td>*/}
                    <td className="border-t-0 font-bold px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                      {event.categorie_name}
                    </td>
                    <td className="border-t-0 font-bold px-4 align-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-right">
                      <a
                        href={"/events/" + event.converted_slug}
                        className={
                          "text-sm rounded py-2 px-4 bg-blue-500 font-normal block w-full  bg-transparent text-white align-left text-center"
                        }
                        style={{ backgroundColor: "#3ACC7A" }}
                      >
                        Voir les photos
                      </a>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
          {filteredEvents.length === 0 && (
            <p
              className={
                "text-center border-t-0 font-bold text-gray-700 px-6 align-center border-l-0 border-r-0 text-m whitespace-no-wrap p-4"
              }
            >
              Aucun résultat
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default withApp(withSnackbar(Events, snackOption));
