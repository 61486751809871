import React, { useEffect, useState } from "react";
import { http } from "../../../engine/http";

const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );

export default function Dashboard() {
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    http.get("/api/validations").then((response) => {
      let gb = groupBy(response.data.photos, "event_id");
      setPhotos(gb);
    });

    return () => {};
  }, []);

  return (
    <div className="flex flex-wrap mt-4">
      <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-gray-800 text-xl font-bold">
                {Object.keys(photos).length === 0
                  ? "Vous êtes à jour, aucune photo à valider"
                  : "Photos à valider par évènement"}
              </h6>
            </div>
          </div>
          {Object.keys(photos).map((id) => {
            let rows = [];
            let max = photos[id].length;
            let rest = 0;
            let limit = 8;

            if (max >= limit) {
              max = limit;
            }

            for (let i = 0; i < max; i++) {
              rows.push(
                <div className="" key={i}>
                  <img
                    className="block  "
                    style={{
                      display: "block",
                      height: "50px",
                      width: "auto",
                      marginTop: "5px",
                      marginLeft: "5px",
                    }}
                    src={"/" + photos[id][i].file_source}
                  />
                </div>
              );
            }

            return (
              <div className=" w-full  flex-auto p-4 pt-0 bg-gray-300 mt-2 rounded  ">
                <form>
                  <div className="text-center flex justify-between ">
                    <h6 className="text-gray-500 text-base mt-6 font-bold uppercase">
                      {photos[id][0].event_name}
                    </h6>
                    <a
                      href={"/admin/events/" + id + "/validation"}
                      className={
                        "rounded text-base py-2 px-2 mt-4 bg-green-500 font-normal block bg-transparent text-white align-left text-center"
                      }
                    >
                      Vous avez {photos[id].length} photo
                      {photos[id].length > 1 && "s"} à valider
                    </a>
                  </div>
                  <div className=" w-full flex p-2 flex-wrap">{rows}</div>
                </form>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
