import React, { useEffect, useState, createRef } from "react";
import { useDropzone } from "react-dropzone";
import "../assets/css/dropzone.css";
import "react-dropdown/style.css";
import { http } from "../engine/http";
import Resizer from "react-image-file-resizer";
import EXIF from "exif-js";
import { withApp } from "../engine/App.context";
import DropDown from "react-dropdown";
import { useAlert } from "react-alert";
import Pdf from "../assets/CGU-Wepix.pdf";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 150,
  height: 150,
  padding: 4,
  boxSizing: "border-box",
  flexDirection: "column",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "150px",
  height: "150px",
  objectFit: "cover",
};

export const maxFiles = 200;

function DropZone(props) {
  const alert = useAlert();
  const [files, setFiles] = useState([]);
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [nom, setNom] = useState("");
  const [honey, setHoney] = useState("");
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState(null);
  const [cond, setCond] = useState(false);
  const [thanks, setThanks] = useState(false);
  const [loader, setLoader] = useState(false);
  const [sending, setSending] = useState(false);
  const [loadingNumber, setLoadingNumber] = useState(0);

  useEffect(() => {
    setLoader(false);
    setLoadingNumber(0);
  }, [files]); //o change files

  const resizeFile = (file, quality) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1920,
        1080,
        "JPEG",
        quality,
        0,
        async (uri) => {
          let concatObject = await Object.assign(uri, {
            name: file.name,
            path: file.path,
            exifdata: file.exifdata,
            preview: URL.createObjectURL(uri),
          });
          resolve(concatObject);
        },
        "blob",
        200,
        200
      );
    });

  // accept: "image/*",
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    maxFiles: maxFiles,
    accept: "image/jpeg, image/png",
    onDrop: async (acceptedFiles) => {
      setLoader(true);
      setLoadingNumber(acceptedFiles.length);
      const promisesCompression = await acceptedFiles.map(async (file) => {
        await EXIF.getData(file, function () {
          const allMetaData = EXIF.getAllTags(this);
        });

        let quality = 100;

        if (file.size > 300000) {
          quality = 50;
        }

        const image = await resizeFile(file, quality);


        return image;
      });

      Promise.all(promisesCompression).then((values) => {
        if (Object.keys(values).length > 0) {
          alert.show(
            <p>Validation et optimisation de vos images avec succès</p>,
            {
              timeout: 5000, // custom timeout just for this one alert
              type: "success",
            }
          );
        }

        setFiles(files.concat(values));
      });
    },
  });

  if (Object.keys(fileRejections).length > 0) {
    props.updateFileRejection(fileRejections);
  }

  function bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  const deleteImage = (e, image) => {
    e.preventDefault();

    setFiles(files.filter((item) => item.name !== image.name));
  };

  const validation = () => {
    if (!cond) {
      alert.show(<p>Merci de valider les conditions générales</p>, {
        timeout: 5000, // custom timeout just for this one alert
        type: "error",
      });
      return false;
    }

    if (event === null) {
      alert.show(<p>Merci de sélectionner un évènement</p>, {
        timeout: 5000, // custom timeout just for this one alert
        type: "error",
      });
      return false;
    }

    if (honey.length > 0) {
      alert.show(<p>Il semblerait que vous n'etes pas humain...</p>, {
        timeout: 5000, // custom timeout just for this one alert
        type: "error",
      });
      return false;
    }

    if (files.length === 0) {
      alert.show(<p>Merci de sélectionner vos photos</p>, {
        timeout: 5000, // custom timeout just for this one alert
        type: "error",
      });
      return false;
    }
  };

  const send = (e) => {
    setSending(true);

    e.preventDefault();

    // return false;

    if (validation() === false) {
      return false;
    }

    const dataPost = new FormData();

    dataPost.append("text-x", "super name of image");
    dataPost.append("nom", nom);
    dataPost.append("prenom", prenom);
    dataPost.append("email", email);
    dataPost.append("event", event);

    const jsonInfo = [];
    for (let x = 0; x < files.length; x++) {
      dataPost.append("file", files[x], files[x].name);
      jsonInfo.push(files[x]);
    }

    dataPost.append("filesinfo", JSON.stringify(jsonInfo));

    http.post("/api/upload", dataPost).then((res) => {
      if (res.status === 200) {
        setSending(false);
        setFiles([]);
        setCond(false);
        setThanks(true);
      }
      setSending(false);
    });
  };

  const dropzoneRef = createRef();
  const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const thumbs = files.map((file) => {
    return (
      <div style={thumb} key={file.name}>
        <a
          href={"#"}
          onClick={(e) => deleteImage(e, file)}
          className={"text-white delete font-semibold text-2xl"}
        >
          X
        </a>
        <div style={thumbInner}>
          <img src={file.preview} style={img} />
        </div>
      </div>
    );
  });

  useEffect(() => {
    http
      .get("/api/events_upload/")
      .then((events) => setEvents(events.data.events));

    // Make sure to revoke the data uris to avoid memory leaksnpm
    files.forEach((file) => URL.revokeObjectURL(file.preview));

    return () => {};
  }, [files]);

  const formDragAndDrop = () => {
    return (
      <header
        className=" py-12 flex flex-col justify-center items-center text-white"
        {...getRootProps({ className: "dropzone" })}
      >
        <input {...getInputProps()} />

        <p className="mb-3 font-normal text-white flex flex-wrap justify-center">
          <span>Glissez et déposez vos images n'importe où</span>
        </p>
        <input id="hidden-input" type="file" multiple className="hidden" />
        <button
          type={"button"}
          id="button"
          onClick={openDialog}
          className="mt-2 rounded-sm px-3 py-1 font-normal  bg-gray-200 hover:bg-gray-300 focus:shadow-outline focus:outline-none"
        >
          Sélectionner les images
        </button>
      </header>
    );
  };

  return (
    <form onSubmit={send} name={"form-upload"} className={"form-upload  "}>
      <section
        style={{ maxWidth: "1024px", margin: "0 auto" }}
        className="max-w-screen-lg blurblack object-center place-self-center  h-full"
      >
        <section className="h-full overflow-auto p-8 w-full h-full flex flex-col">
          <h1 className={"text-white font-black text-4xl text-shadow"}>
            Partagez vos meilleurs clichés
          </h1>

          {thanks ? (
            <>
              <h1 className={"text-white font-black text-xl text-shadow"}>
                Nous avons bien reçu vos images et nous vous en remercions !{" "}
                <br />
                Vos images vont maintenant rentrer dans un processus de
                validation avant publication sur notre site.
              </h1>
              <button
                onClick={() => setThanks(false)}
                id="button"
                className="mt-2 text-3xl font-semibold rounded-sm px-3 py-1 bg-gray-200 hover:bg-gray-300 focus:shadow-outline focus:outline-none"
              >
                Nouvel envoi de photos
              </button>
            </>
          ) : (
            <>
              {events.length > 0 ? (
                <>
                  <DropDown
                    className={
                      " placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    }
                    required
                    options={events.map((cat) => {
                      return { value: String(cat.id), label: cat.name };
                    })}
                    value={event}
                    onChange={(e) => setEvent(e.value)}
                    placeholder="Choisir l'évènement..."
                  />
                  <div className={"flex "}>
                    <div className={"w-1/2 p-2"}>
                      <input
                        onChange={(e) => setNom(e.target.value)}
                        id="nom"
                        type="nom"
                        name="nom"
                        placeholder="Votre nom"
                        autoComplete="given-name"
                        className="block w-full p-3 mt-2 mr-2 placeholder-white text-white bg-transparent appearance-none border-b-2 focus:outline-none focus:border-2 focus:border-white focus:shadow-xl"
                        required
                        value={nom}
                      />
                    </div>
                    <div className={"w-1/2 p-2"}>
                      <input
                        onChange={(e) => setPrenom(e.target.value)}
                        id="prenom"
                        type="prenom"
                        name="prenom"
                        placeholder="Votre prénom"
                        autoComplete="family-name"
                        className="block w-full p-3 mt-2 mr-2 placeholder-white text-white bg-transparent appearance-none border-b-2 focus:outline-none focus:border-2 focus:border-white focus:shadow-xl"
                        required
                        value={prenom}
                      />
                    </div>
                  </div>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    id="email"
                    type="email"
                    name="email"
                    placeholder="Votre email"
                    autoComplete="email"
                    className="font-normal block w-full p-3 mt-2 mr-2 placeholder-white text-white bg-transparent appearance-none border-b-2 focus:outline-none focus:border-2 focus:border-white focus:shadow-xl"
                    required
                    value={email}
                  />
                  <br />
                  <br />

                  {loader && (
                    <div
                      className={"object-center place-self-center text-center"}
                    >
                      <img
                        src={require("../assets/img/loader.svg")}
                        style={{ margin: "0 auto" }}
                      />
                      <span className={"text-m text-white font-normal "}>
                        Préparation en cours de {loadingNumber} images...
                      </span>
                    </div>
                  )}

                  {sending && (
                    <div
                      className={"object-center place-self-center text-center"}
                    >
                      <img
                        src={require("../assets/img/loader.svg")}
                        style={{ margin: "0 auto" }}
                      />
                      <span className={"text-m text-white font-normal "}>
                        Envoi en cours de vos {files.length} images...
                      </span>
                    </div>
                  )}

                  {!loader && !sending ? formDragAndDrop() : null}

                  <input
                    onChange={(e) => setHoney(e.target.value)}
                    value={honey}
                    name={"honey"}
                    type="text"
                    className="opacity-0"
                    onClick={(e) => setCond(!cond)}
                  />

                  <ul id="gallery" className="flex flex-1 flex-wrap -m-1">
                    <li
                      id="empty"
                      className="h-full w-full text-center flex flex-col items-center justify-center items-center"
                    >
                      {files.length ? (
                        <aside style={thumbsContainer}>{thumbs}</aside>
                      ) : (
                        <>
                          <img
                            className="mx-auto w-32"
                            src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                            alt="no data"
                          />
                          <br />
                          <span className="text-m text-white font-normal  ">
                            Aucune image sélectionnée
                          </span>
                          <br />
                          <br />
                        </>
                      )}
                    </li>
                  </ul>
                  <label className="flex justify-start items-start">
                    <div className="bg-white border-2 rounded border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500">
                      <input
                        name={"condition"}
                        required
                        checked={cond}
                        type="checkbox"
                        className="opacity-0 absolute"
                        onClick={(e) => setCond(!cond)}
                      />
                      <svg
                        className="fill-current hidden w-4 h-4 text-green-500 pointer-events-none"
                        viewBox="0 0 20 20"
                      >
                        <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                      </svg>
                    </div>
                    <br />
                    <br />
                    <div className="select-none text-white text-l font-normal">
                      <a onClick={() => window.open(Pdf)}>
                        Condition générales
                      </a>
                    </div>
                    <br />
                  </label>
                  <button
                    id="button"
                    type={"submit"}
                    className="mt-2 text-3xl font-semibold rounded-sm px-3 py-1 bg-gray-200 hover:bg-gray-300 focus:shadow-outline focus:outline-none"
                  >
                    Envoyer
                  </button>
                </>
              ) : (
                <>
                  <span className={"text-white font-black text-lg text-shadow"}>
                    Il n'y a aucun évènement actuellement disponible
                  </span>
                </>
              )}
            </>
          )}
        </section>
      </section>
    </form>
  );
}

export default withApp(DropZone);
