import React, { useState } from "react";
import moment from "moment";

export default function ListEventsTable({ color, events }) {
  const [search, setSearch] = useState("");

  let filteredEvents = events.filter((event) => event.name.includes(search));

  return (
    <>
      <form className="md:flex  flex-row flex-wrap items-center lg:ml-auto mr-3">
        <div className="relative flex w-full flex-wrap items-stretch">
          <span className="z-10 h-full leading-snug font-normal absolute text-center text-gray-400 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
            <i className="fas fa-search" />
          </span>
          <input
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            placeholder="Rechercher..."
            className="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full pl-10"
          />
        </div>
      </form>
      <br />
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-blue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-gray-800" : "text-white")
                }
              >
                Liste des événements
              </h3>
            </div>
            <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
              <a
                className="rounded bg-yellow-500 text-white active:bg-yellow-600 text-xxl font-bold  uppercase px-3 py-1  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                href={"/admin/upsert"}
              >
                <i className="fas fa-plus mr-2 text-xxl" />
                Ajouter un évènement
              </a>
            </div>
          </div>
        </div>

        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >
                  Nom
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >
                  Description
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >
                  Date de début
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >
                  Date de fin
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >
                  Catégorie
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredEvents.map((event) => (
                <>
                  <tr className={"border"}>
                    <td className="border-t-0 font-bold text-gray-700 px-6 align-middle border-l-0 border-r-0 text-m whitespace-no-wrap p-4">
                      {event.name}
                    </td>
                    <td className="border-t-0 font-bold text-gray-700 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                      {event.description}
                    </td>
                    <td className="border-t-0 font-bold px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                      {moment(event.start_date).format("D MMMM YYYY")}
                    </td>
                    <td className="border-t-0 font-bold px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                      {moment(event.end_date).format("D MMMM YYYY")}
                    </td>
                    <td className="border-t-0 font-bold px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                      {event.categorie_name}
                    </td>
                    <td className="border-t-0 font-bold px-6 align-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-right">
                      <a
                        href={"/admin/upsert/" + event.id}
                        className={
                          "text-sm rounded py-2 px-4 bg-blue-500 font-normal block w-full  bg-transparent text-white align-left text-center"
                        }
                      >
                        <i className="fas fa-edit mr-2 text-xs" />
                        Editer
                      </a>
                      <br />
                      <a
                        href={"/admin/events/" + event.id + "/validation"}
                        className={
                          "rounded text-sm py-2 px-4 bg-green-500 font-normal block w-full  bg-transparent text-white align-left text-center"
                        }
                      >
                        <i className="fas fa-check mr-2 text-xs" />
                        Valider les photos
                      </a>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

ListEventsTable.defaultProps = {
  color: "light",
};
