import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import HeaderStats from "../components/Headers/HeaderStats.js";
import FooterAdmin from "../components/Footers/FooterAdmin.js";

// views

import Dashboard from "../views/admin/Dashboard.js";
import UpsertEventView from "../views/admin/UpsertEvent.view.js";
import Events from "../views/admin/Events.screen";
import Users from "../views/admin/Users.screen";
import Validation from "../views/admin/Validation.screen";

export default function Admin() {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-gray-200">
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
            <Route path="/admin/dashboard" exact component={Dashboard} />
            <Route path="/admin/events" exact component={Events} />
            <Route path="/admin/users" exact component={Users} />
            <Route path="/admin/upsert/:id" component={UpsertEventView} />
            <Route path="/admin/upsert" component={UpsertEventView} />
            <Route path="/admin/events/:id/validation" component={Validation} />
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
