import React from "react";
import { withApp } from "../../../engine/App.context";
import { withSnackbar } from "react-simple-snackbar";
import { withRouter } from "react-router";
import { snackOption } from "../../../engine/config";
import { http } from "../../../engine/http";
import moment from "moment";
import DropDown from "react-dropdown";

// components

class UpsertEventForms extends React.Component {
  state = {
    firstSlug: "",
    name: "",
    slug: "",
    converted_slug: "",
    start_date: "",
    end_date: "",
    category: "",
    description: "",
    initialization: false,
    is_public: null,
    can_upload: null,
  };

  update = (e) => {
    e.preventDefault();
    let {
      slug,
      name,
      start_date,
      end_date,
      description,
      category,
      converted_slug,
      firstSlug,
      is_public,
      can_upload,
    } = this.state;
    const { id } = this.props.match.params;

    if (slug === "" || /^\d+$/.test(slug)) {
      converted_slug = this.convertToRul(name);
      slug = name;
    }

    if (slug !== firstSlug) {
      if (
        !window.confirm(
          "Attention tout changement du slug de l'évènement aura un impact sur son URL !"
        )
      ) {
        return false;
      }
    }

    http
      .put("/api/events/" + id, {
        slug,
        converted_slug,
        description,
        name,
        is_public,
        can_upload,
        category,
        start_date: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
      })
      .then((response) => {
        this.props.openSnackbar("Evènement mis à jour avec succès");
        this.setState({ slug, converted_slug });
      });
  };

  // Hydrate the component if update or create
  componentDidMount() {
    const { initialization } = this.state;

    // ID params get the event data
    if (initialization === false && this.props.match.params.id !== undefined) {
      http.get("/api/events/" + this.props.match.params.id).then((response) => {
        const { events } = response.data;

        // If data empty that mean event does not exist
        if (events === undefined) {
          this.props.history.push("/admin/upsert");
        } else {
          // Hydrate state
          // Convert data and date
          const start_date = moment(events.start_date).format(
            "YYYY-MM-DDTHH:mm"
          );
          const end_date = moment(events.end_date).format("YYYY-MM-DDTHH:mm"); //entree
          const category = String(response.data.events.categories_id);

          this.setState({
            firstSlug: response.data.events.slug,
            initialization: true,
            ...response.data.events,
            category,
            start_date,
            end_date,
          });
        }
      });
    }
  }

  delete = (e) => {
    e.preventDefault();
    if (!window.confirm("Voulez-vous vraiment supprimer cet évènement ?")) {
      return false;
    }
    const { id } = this.props.match.params;

    // POST data
    http.delete("/api/events/" + id).then((response) => {
      // Update component
      this.props.openSnackbar("Evènement supprimé avec succès");
      window.location = "/admin/events";
    });
  };

  convertToRul = (name) => {
    return encodeURI(name.replace(/\//g, ""));
  };

  create = (e) => {
    e.preventDefault();
    let {
      name,
      start_date,
      end_date,
      description,
      category,
      slug,
      converted_slug,
    } = this.state;

    if (slug === "" || /^\d+$/.test(slug)) {
      converted_slug = this.convertToRul(name);
      slug = name;
    }
    // POST data
    http
      .post("/api/events", {
        can_upload: "false",
        is_public: "false",
        converted_slug,
        slug,
        description,
        name,
        category,
        start_date: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
      })
      .then((response) => {
        // Update component
        this.props.openSnackbar("Evènement créer avec succès");
        this.props.history.push("/admin/upsert/" + response.data.insertId);
        this.setState({
          slug,
          converted_slug,
          firstSlug: slug,
          can_upload: "false",
          is_public: "false",
        });
      });
  };

  render() {
    const {
      name,
      start_date,
      end_date,
      description,
      category,
      converted_slug,
      slug,
      is_public,
      can_upload,
    } = this.state;
    const { categories } = this.props.app;

    const { id } = this.props.match.params;

    return (
      <>
        <form onSubmit={id === undefined ? this.create : this.update}>
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-gray-800 text-xl font-bold">
                  {id === undefined ? "Créer un évènement" : name}
                </h6>
                <div>
                  <button
                    className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    <i className="fas fa-save mr-2 text-xxl" />
                    {id === undefined ? "Créer" : "Mettre à jour"}
                  </button>
                  {id !== undefined && (
                    <button
                      onClick={this.delete}
                      type="button"
                      className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    >
                      <i className="fas fa-trash mr-2 text-xxl" />
                      Supprimer
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              {id !== undefined && (
                <>
                  <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
                    Status de l'évènement
                  </h6>
                  <div className="flex flex-wrap">
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Est-ce que les photos validées sont visibles ?
                        </label>
                        <button
                          onClick={() => this.setState({ is_public: "true" })}
                          className={
                            "text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" +
                            (is_public === "true"
                              ? " bg-green-500 "
                              : " bg-gray-500 ")
                          }
                          type="button"
                        >
                          <i className="fas fa-check mr-2 text-xxl" />
                          Visibles
                        </button>
                        <button
                          onClick={() => this.setState({ is_public: "false" })}
                          className={
                            " text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" +
                            (is_public === "false"
                              ? " bg-red-500 "
                              : " bg-gray-500 ")
                          }
                          type="button"
                        >
                          <i className="fas fa-eye-slash mr-2 text-xxl" />
                          Cachées
                        </button>
                      </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Peut t'ont envoyer de nouvelles photos ?
                        </label>
                        <button
                          onClick={() => this.setState({ can_upload: "true" })}
                          className={
                            "text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" +
                            (can_upload === "true"
                              ? " bg-green-500 "
                              : " bg-gray-500 ")
                          }
                          type="button"
                        >
                          <i className="fas fa-check mr-2 text-xxl" />
                          Oui
                        </button>
                        <button
                          onClick={() => this.setState({ can_upload: "false" })}
                          className={
                            " text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" +
                            (can_upload === "false"
                              ? " bg-red-500 "
                              : " bg-gray-500 ")
                          }
                          type="button"
                        >
                          <i className="fas fa-ban mr-2 text-xxl" />
                          Non
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-6 border-b-1 border-gray-400" />
                </>
              )}

              <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
                Informations de base
              </h6>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Nom
                    </label>
                    <input
                      value={name}
                      id="name"
                      required
                      onChange={(e) => this.setState({ name: e.target.value })}
                      name="name"
                      type="text"
                      placeholder={"Comment se nomme l'évènement ?"}
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Slug (unique, nom de l'url, selon standards du web)
                    </label>
                    <input
                      value={slug}
                      id="name"
                      onChange={(e) => {
                        this.setState({ slug: e.target.value });
                        this.setState({
                          converted_slug: this.convertToRul(e.target.value),
                        });
                      }}
                      name="name"
                      type="text"
                      placeholder={"Une jolie url de publication"}
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    />
                    <p
                      className={" block  text-gray-700 text-xs font-bold m-1"}
                    >
                      Votre url convertie : {converted_slug}
                    </p>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Catégorie
                    </label>
                    <DropDown
                      className={
                        "px-2 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      }
                      options={categories.map((cat) => {
                        return { value: String(cat.id), label: cat.name };
                      })}
                      value={category}
                      onChange={(e) => this.setState({ category: e.value })}
                      placeholder="Choisir..."
                    />
                  </div>
                </div>
                <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Description
                    </label>
                    <textarea
                      value={description}
                      id="description"
                      name="description"
                      type="text"
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                      placeholder={"Qu'elle est le résumé de l'évènement ?"}
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      rows="4"
                    />
                  </div>
                </div>
              </div>

              <hr className="mt-6 border-b-1 border-gray-400" />

              <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
                Date
              </h6>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="start_date"
                    >
                      Date de début
                    </label>
                    <input
                      value={start_date}
                      id="start_date"
                      type="datetime-local"
                      name="start_date"
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      required
                      onChange={(e) =>
                        this.setState({ start_date: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="end_date"
                    >
                      Date de fin
                    </label>
                    <input
                      value={end_date}
                      id="end_date"
                      type="datetime-local"
                      name="end_date"
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      required
                      onChange={(e) =>
                        this.setState({ end_date: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>

              <hr className="mt-6 border-b-1 border-gray-400" />

              {/*<h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">*/}
              {/*  Filigramme*/}
              {/*</h6>*/}
              {/*<div className="flex flex-wrap">*/}
              {/*  <div className="w-full lg:w-12/12 px-4">*/}
              {/*    <div className="relative w-full mb-3">*/}
              {/*      <label*/}
              {/*        className="block uppercase text-gray-700 text-xs font-bold mb-2"*/}
              {/*        htmlFor="grid-password"*/}
              {/*      >*/}
              {/*        Filigramme ici*/}
              {/*      </label>*/}
              {/*      <input*/}
              {/*        type="text"*/}
              {/*        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"*/}
              {/*        defaultValue="A venir"*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </form>
      </>
    );
  }
}

export default withApp(withSnackbar(withRouter(UpsertEventForms, snackOption)));
